import {Inject, Injectable} from '@angular/core';
import {ApiService, API_SERVICE} from 'ng-upgrade-api';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class MessageService {
  constructor(@Inject(API_SERVICE) private apiService: ApiService) {
  }

  // 获取消息列表
  GetMessageItems(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson<any>(`Message/GetMessageItems`, {body: model});
  }

  // 设置所有已读
  SetMessageAllReaded(): Observable<any> {
    return this.apiService.getJson<any>(`Message/SetMessageAllReaded`, {});
  }

  // 设置单条消息已读
  SetMessageReaded(messageId: string): Observable<any> {
    return this.apiService.getJson<any>(`Message/SetMessageReaded/${messageId}`, {});
  }

  // 设置单条消息未读
  SetMessageUnread(messageId: string): Observable<any> {
    return this.apiService.getJson<any>(`Message/SetMessageUnread/${messageId}`, {});
  }


  // 删除消息
  RemoveMessage(messageId: string): Observable<any> {
    return this.apiService.getJson<any>(`Message/RemoveMessage/${messageId}`, {});
  }

  // 获取公告内容
  GetNotice(): Observable<any> {
    return this.apiService.getJson(`Message/GetNotice`, {});
  }

}
