import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

// import 'hammerjs';  // 引入hammerjs

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

// if (environment.hmr) {
//   if (module['hot']) {
//     hmrBootstrap(module, bootstrap);
//   } else {
//     console.error('HMR is not enabled for webpack-dev-job!');
//     console.log('Are you using the --hmr flag for ng serve?');
//   }
// } else {
document.addEventListener('DOMContentLoaded', () => {
  bootstrap().catch((err) => console.log(err));
});
// }
