import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'xsk-no-data',
  template: `
    <div class="div-wrap" [ngStyle]="{'marginTop':marginTop}">
      <img src="assets/icon/no_data.png" width="70">
    </div>
  `,
  styles: [`
    .div-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      margin-top: 35%;
    }
  `],
})
export class NoDataComponent implements OnInit {
  @Input() marginTop = '35%';

  constructor() {
  }

  ngOnInit() {
  }

}
