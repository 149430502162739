import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { log } from 'console';
import { AppUserService } from 'src/app/services/app-user.service';
import { TutorialCallPage } from '../../tutorialindex/tutorial-call/tutorial-call.page';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'xsk-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss'],
})
export class PrivacyModalComponent implements OnInit {
  // 接口获取到的知情同意书内容 显示
  @Input() paraContent: any;
  @Input() informedConsentName: string;
  @Input() buttons: any;
  @Input() versionNumber: any;
  @Input() platform: any;

  // 浏览状态判断
  isTouchBottom: boolean = false;
  // 多选框的状态
  checkAllFlag: boolean = false;
  observer: IntersectionObserver;

  constructor(
    private sanitizer: DomSanitizer,
    private appUserService: AppUserService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    this.paraContent = this.sanitizer.bypassSecurityTrustHtml(this.paraContent);
  }

  // 同意/不同意 知情同意书
  agreePrivacy(type: boolean) {
    // if (!type) {
    //   this.modalController.dismiss({}, 'cancel');
    // } else{
    this.appUserService
      .SaveUserAgreeRecordPost({
        platform: this.platform,
        versionNumber: this.versionNumber,
        isConsent: type,
      })
      .subscribe((res) => {
        console.log(res);
        this.modalController.dismiss({}, type ? 'success' : 'cancel');
      });
    // }
  }

  // angular钩子 Dom加载完毕
  ngAfterViewInit(): void {
    if (this.buttons.length > 0) {
      this.checkAllFlag = false;
    } else {
      this.checkAllFlag = true;
    }
    console.log('ngAfterViewInit中的时间', this.checkAllFlag);

    this.observer = new IntersectionObserver(
      (entries) => {
        // 在这里处理交叉观察到的元素状态
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // console.log('目标元素已经进入视窗');
            this.isTouchBottom = true;
          } else {
            // console.log('目标元素已经离开视窗');
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.5,
      }
    );

    // 将需要观察的元素添加到观察列表中
    const target = document.querySelector('#test');
    this.observer.observe(target);
  }

  // 查看是否全选
  updateAllChecked(val: any) {
    this.checkAllFlag = true;
    for (const item of this.buttons) {
      if (!item.isConsent) {
        this.checkAllFlag = false;
      }
    }
    //console.log('查看是否全选',this.checkAllFlag);
  }
}
