import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GesturePassComponent} from './gesture-pass.component';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
// import {GesturePasswordModule} from 'ngx-gesture-password';


@NgModule({
  declarations: [GesturePassComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    // GesturePasswordModule
  ],
  exports: [GesturePassComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GesturePassModule {
}
