import {Camera} from '@ionic-native/camera/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {ApiConfig} from 'ng-upgrade-api';
import {
  GLOBAL_STATE_LOCAL_STORAGE_PROVIDER,
  API_STATE_LOCAL_STORAGE_PROVIDER,
  API_SERVICE_IONIC_NATIVE_PROVIDER,
  INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD
} from 'ng-upgrade-basis';
import {environment} from '../environments/environment';
import {Provider} from '@angular/core';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Device} from '@ionic-native/device/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {UniqueDeviceID} from '@ionic-native/unique-device-id/ngx';
// import {ImagePicker} from '@ionic-native/image-picker/ngx';

const APP_PROVIDERS: Provider[] = [
  {provide: INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD, useValue: environment},
  Camera,
  StatusBar,
  SplashScreen,
  AppVersion,
  File,
  FileOpener,
  FileTransfer,
  Device,
  InAppBrowser,
  UniqueDeviceID,
  {provide: ApiConfig, useValue: {root: environment.apiRoot}},
  GLOBAL_STATE_LOCAL_STORAGE_PROVIDER,
  API_STATE_LOCAL_STORAGE_PROVIDER
];
if (environment.isApp && !environment.hmr) {
  APP_PROVIDERS.push(API_SERVICE_IONIC_NATIVE_PROVIDER);
}
export {APP_PROVIDERS};
