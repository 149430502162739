<ion-content [forceOverscroll]="true" [fullscreen]="true" [scrollEvents]="true" slot="fixed" class="bg-white">
  <ion-card style="margin-top: 30%; padding-top: 26px; padding-left: 16px;">
    <div class="logo-box" style="margin-top: 16px;">
      <div class="input-box">
        <img src="assets/icon/login_account.png" width="16" class="input-icon">
        <input type="text" placeholder="邮箱账号" placeholder-style="font-size:12px !important"
               disabled="true"
          style="border: none" [(ngModel)]="userName">
      </div>
      <div style="width: 100%;height: 15px"></div>
      <div class="input-box">
        <img src="assets/icon/login_psw.png" width="16" class="input-icon">
        <input type="password" placeholder="LDAPS密码(邮箱密码/开机密码)" placeholder-style="font-size:12px !important"
          style="border: none" [(ngModel)]="password">
      </div>
    </div>
    <div style="width: 100%;padding: 20px 10px;display: flex;justify-content: center">
      <ion-button shape="round" size="large" expand="block" style="width: 80%;height: 45px;font-size: 15px" color="sky"
        (click)="doLogin()">
        登录
      </ion-button>
    </div>
  </ion-card>
</ion-content>
