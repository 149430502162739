import {Inject, Injectable} from '@angular/core';
import {ApiService, API_SERVICE} from 'ng-upgrade-api';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionUpdateService {

  constructor(@Inject(API_SERVICE) private apiService: ApiService) {
  }

  GetVersionModify(model: any): Observable<any> {
    return this.apiService.postJson(`AutoEquipment/GetVersionModify`, {
      body: model
    });
  }

}
