import {Injectable} from '@angular/core';
import {GlobalVariable} from '../config/variable';
import {MessageService} from '../services/message.service';
import {AlertInfoProvider} from './alertInfo.provider';

@Injectable({providedIn: 'root'})
export class PopupNoticeService {
  constructor(private messageService: MessageService,
              private alertInfoProvider: AlertInfoProvider) {
  }

  // 获取公告内容
  getNotice = () => new Promise(resolve2 => {
    if (!GlobalVariable.noticeIsShow) {
      resolve2(null);
      return;
    }
    this.messageService.GetNotice().subscribe(res => {
      if (res && res.isShowNotice) {
        this.alertInfoProvider.presentAlertByTitle('提示', res.noticeDetail);
      }
      resolve2(null);
    });
  })

}
