
export const decorateEnv = (env) => {
    env.build.buildDate = '20240801031848';
    env.build.buildDateEpoch = 1722482328089;
    env.build.buildRevision = '050ce945b46572a119ff4bd021bc572ccfca4a41';
    env.build.project = 'abbottamd';
    env.build.part = 'rep';
    env.build.deployment = 'uat';
    env.build.viewport = 'wow';
    env.apiRoot = 'https://amdsfe-api-s.abbott.com.cn';
    env.production = 'false';
    env.variables.corpId = 'wx7dbaf21fd68d3c7a';
    env.variables.mapApiKey = 'ee95e52bf08006f63fd29bcfbcf21df0';
};
