import {Injectable} from '@angular/core';
import {AlertController, LoadingController, ModalController, Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {GlobalVariable} from '../config/variable';

@Injectable({
  providedIn: 'root'
})
export class PagefixService {
  globalVariable = GlobalVariable;

  constructor(private platform: Platform,
              private title: Title,
              private router: Router,
              private modalController: ModalController,
              private loadingController: LoadingController,
              private alertController: AlertController) {
  }

  // 处理安全距离 项目初始化，切换横竖屏，切换路由的时候都要检测
  dealSafeArea() {
    if (this.platform.is('ios')) {
      // 如果是x或者xr
      if (window.screen.height === 812 || window.screen.height === 896) {
        // 如果大于一定值说明此时没有安全距离  690  724 | 808  852
        // tslint:disable-next-line:max-line-length
        if ((window.screen.height === 812 && document.body.scrollHeight >= 724) || (window.screen.height === 896 && document.body.scrollHeight >= 808)) {
          document.getElementsByTagName('html')[0].style.paddingBottom = '34px';
        } else {
          document.getElementsByTagName('html')[0].style.paddingBottom = '0';
        }
      }
    }
  }

  fixSafeArea() {
    if (!environment.isApp) {
      this.dealSafeArea();
      window.addEventListener('orientationchange', () => {
        this.dealSafeArea();
      });
    }
  }

  closeLoading() {
    this.globalVariable.loadingIsShow = false;
    this.loadingController.getTop().then(loading => {
      if (loading) {
        // loading.dismiss();
        this.loadingController.dismiss();
      }
    });
  }

  closeAlerting() {
    // 页面返回关闭 alert
    this.alertController.getTop().then(alert => {
      if (alert) {
        // alert.dismiss();
        this.alertController.dismiss();
      }
    });
    this.globalVariable.alertingIsShow = false;
    this.globalVariable.alertingMessage = '';
  }

  closeAllPageLayer() {
    this.globalVariable.alertingIsShow = false;
    this.globalVariable.alertingMessage = '';
    this.globalVariable.loadingIsShow = false;
    // 页面返回 关闭modal
    this.modalController.getTop().then(modal => {
      if (modal) {
        // modal.dismiss();
        this.modalController.dismiss();
      }
    });
    // 页面返回 关闭菊花
    this.loadingController.getTop().then(loading => {
      if (loading) {
        // loading.dismiss();
        this.loadingController.dismiss();
      }
    });
    // 页面返回关闭 alert
    this.alertController.getTop().then(alert => {
      if (alert) {
        // alert.dismiss();
        this.alertController.dismiss();
      }
    });
  }


  setTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe((event) => {
        this.closeAllPageLayer();
        // 更改页面title
        const titles = this.getTitle(this.router.routerState, this.router.routerState.root);
        const title = titles[titles.length - 1];
        if (title) {
          this.title.setTitle(title);
        }
      });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
