import { Component, Inject } from '@angular/core';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApinfoService } from './providers/apinfo.service';
import { environment } from '../environments/environment';
import { GlobalVariable } from './config/variable';
import { PagefixService } from './providers/pagefix.service';
import { GeneralService } from 'ng-upgrade-common';
import { SwitchTerritoryModalComponent } from './pages/login/switch-territory-modal/switch-territory-modal.component';
import { AlertInfoProvider } from './providers/alertInfo.provider';
import {
  BootstrapComponentBase,
  INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD,
  EnvironmentWithBuildInfo,
} from 'ng-upgrade-basis';
import { AppUserService } from './services/app-user.service';
import VConsole from 'vconsole';
import { GestureProvider } from './providers/gesture.provider';
import { NavigationEnd, Router } from '@angular/router';
import { LoginModalComponent } from './pages/login-modal/login-modal.component';
import { PrivacyModalComponent } from './pages/login/privacy-modal/privacy-modal.component';

const LOCAL_STORAGE_KEY_ACCOUNT = 'acount';
const LOCAL_STORAGE_KEY_TOKEN = 'token';
const LOCAL_STORAGE_KEY_SELECTTERRITORY = '_selectedTerritory';
const GESTURE_PASSWORD = '_gesture';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BootstrapComponentBase {
  environment = environment;
  globalVariable = GlobalVariable;
  territoryList = [];
  isShowGoHome = true;
  isTest = false;

  constructor(
    @Inject(INJECTION_TOKEN_ENVIRONMENT_WITH_BUILD)
    envWithBuild: EnvironmentWithBuildInfo = null,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apinfoService: ApinfoService,
    private pagefixService: PagefixService,
    private navController: NavController,
    private modalController: ModalController,
    private alertInfoProvider: AlertInfoProvider,
    private appUserService: AppUserService,
    private gestureProvider: GestureProvider,
    private router: Router,
    public generalService: GeneralService
  ) {
    super(envWithBuild, generalService);
    document.title = GlobalVariable.title;
    this.generalService.subscribeEvent(['landingSuccess'], async (res) => {
      console.log('首次获取token-------------', localStorage.getItem('token'));
      const accountInfo: any = JSON.parse(localStorage.getItem('acount') ?? '{}');
      localStorage.setItem('_staffId',accountInfo.staffId);
      await this.apinfoService.getAccountInfo();
      // let app_token = localStorage.getItem('app_token');
      // console.log('首次获取app_token', localStorage.getItem('app_token'));
      // if (app_token) {
      //   localStorage.clear();
      //   localStorage.setItem('token', app_token);
      // }
      console.log('赋值之后获取token', localStorage.getItem('token'));
      // 多因素验证 邮箱
      this.appUserService.getRSAPubKey().subscribe((r) => {
        this.globalVariable.rsaPublickKey = r;
        localStorage.setItem('rsaPubKey', r);
        this.appUserService
          .GetIsEnableLogin()
          .subscribe(async (IsEnableLogin) => {
            console.log('IsEnableLoginIsEnableLoginIsEnableLogin',IsEnableLogin);
            if (IsEnableLogin) {
              const modal = await this.modalController.create({
                component: LoginModalComponent,
                componentProps: {},
                mode: 'ios',
              });
              modal.onDidDismiss().then((res1) => {
                if (res1.role === 'success') {
                  this.getEmailGoPrivacySwitch();
                } else {
                  this.globalVariable.authenticationStatus.status = 'aborted';
                }
              });
              return await modal.present();
            } else {
              this.getEmailGoPrivacySwitch();
            }
          });
      });
    });

    this.generalService.subscribeEvent(['logout'], () => {
      if (environment.isApp) {
        this.navController.navigateRoot(['login']);
        this.pagefixService.closeAlerting();
      }
      localStorage.clear();
      sessionStorage.clear();
    });

    // 杀掉进程唤醒手势密码
    if (environment.isApp) {
      if (
        localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) &&
        localStorage.getItem(GESTURE_PASSWORD)
      ) {
        this.test();
        // this.apinfoService.getKillPressAccountInfo();
        // this.gestureProvider
        //   .showGestureModal('check', true, false)
        //   .then((role) => {
        //     if (role === 'save') {
        //       this.apinfoService.getAccountInfo();
        //       this.generalService.publishEvent('ionViewWillEnter', {});
        //     }
        //   });
      } else {
        this.navController.navigateRoot(['login']);
      }
    }
    this.initializeApp();
  }

  async test() {
    await this.apinfoService.getAccountInfo();
    let app_token = localStorage.getItem('app_token');
    console.log('首次获取app_token', localStorage.getItem('app_token'));
    if (app_token) {
      localStorage.clear();
      localStorage.setItem('token', app_token);
    }
    console.log('赋值之后获取token', localStorage.getItem('token'));
    // 多因素验证 邮箱
    this.appUserService.getRSAPubKey().subscribe((r) => {
      this.globalVariable.rsaPublickKey = r;
      localStorage.setItem('rsaPubKey', r);
      this.appUserService
        .GetIsEnableLogin()
        .subscribe(async (IsEnableLogin) => {
          console.log('IsEnableLoginIsEnableLoginIsEnableLogin', IsEnableLogin);
          if (IsEnableLogin) {
            const modal = await this.modalController.create({
              component: LoginModalComponent,
              componentProps: {},
              mode: 'ios',
            });
            modal.onDidDismiss().then((res1) => {
              if (res1.role === 'success') {
                this.getEmailGoPrivacySwitch();
              } else {
                this.globalVariable.authenticationStatus.status = 'aborted';
              }
            });
            return await modal.present();
          } else {
            this.getEmailGoPrivacySwitch();
          }
        });
    });
  }

  showSwitchTerritory() {
    this.switchTerritory().then((territory) => {
      if (territory.action === 'save') {
        if (environment.isApp) {
          // this.gestureProvider
          //   .showGestureModal('recorder', false, false)
          //   .then((role) => {
              this.navController.navigateRoot(['tabs']).then(() => {
                // if (role === 'save') {
                  this.apinfoService.getAccountInfo();
                  this.generalService.publishEvent(
                    '_switchTerritorySuccess',
                    {}
                  );
                // }
              });
            // });
        } else {
          this.globalVariable.authenticationStatus.status = 'success';
          this.apinfoService.getAccountInfo();
          this.generalService.publishEvent('_switchTerritorySuccess', {});
        }
      }
    });
  }

  async switchTerritory(): Promise<any> {
    this.territoryList =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ACCOUNT) ?? '{}')
        .territoryItems ?? [];
    if (this.territoryList.length === 0) {
      this.alertInfoProvider.presentAlert('当前用户无岗位');
      this.globalVariable.authenticationStatus.status = 'aborted';
      this.globalVariable.authenticationStatus.message = '当前用户无岗位';
      return Promise.resolve({ action: 'cancel' });
    } else if (this.territoryList.length === 1) {
      localStorage.setItem(
        '_selectedTerritory',
        JSON.stringify(this.territoryList[0] ?? {})
      );
      return Promise.resolve({
        action: 'save',
        territory: this.territoryList[0],
      });
    }
    const modal = await this.modalController.create({
      component: SwitchTerritoryModalComponent,
      componentProps: {},
      mode: 'ios',
      cssClass: 'small-modal',
      backdropDismiss: false,
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    localStorage.setItem(
      LOCAL_STORAGE_KEY_SELECTTERRITORY,
      JSON.stringify(data.territory ?? {})
    );
    return Promise.resolve(data);
  }

  // 监听路由变化
  initRouterListen() {
    this.router.events.subscribe((event: any) => {
      // 需要放到最后一个执行 urlAfterRedirects
      if (event instanceof NavigationEnd) {
        this.isShowGoHome =
          document.documentURI.includes('tabs') ||
          document.documentURI.includes('login');
        // 考试禁用返回首页
        this.isTest = document.documentURI.includes('learningtest-deteils');
        this.pagefixService.closeAlerting();
      }
    });
  }

  // 关闭弹窗
  closeAlertModal() {
    this.globalVariable.alertingIsShow = false;
    this.globalVariable.alertingMessage = '';
  }

  // 一键回到首页
  goRootHome() {
    this.navController.navigateRoot('tabs');
  }

  // 邮箱登录成功后，执行获取知情同意和切换岗位
  getEmailGoPrivacySwitch() {
    this.appUserService
      .GetActiveInformedConsent({
        platform: 'NODISTRIBUTOR',
      })
      .subscribe(async (r: any) => {
        if (!r.paraContent) {
          this.showSwitchTerritory();
          return;
        }
        if (r.isConsent) {
          // 已经同意走下一步 切换岗位
          this.showSwitchTerritory();
          return;
        } else {
          const modal1 = await this.modalController.create({
            component: PrivacyModalComponent,
            componentProps: {
              paraContent: r.paraContent,
              buttons: r.buttons,
              informedConsentName: r.informedConsentName,
              versionNumber: r.versionNumber,
              platform: r.platform,
            },
            mode: 'ios',
          });
          modal1.onDidDismiss().then((res1) => {
            if (res1.role === 'success') {
              this.showSwitchTerritory();
            } else {
              if (environment.isApp) {
                this.navController.navigateRoot(['login']);
                this.pagefixService.closeAlerting();
              } else {
                this.globalVariable.authenticationStatus.status = 'aborted';
                this.globalVariable.authenticationStatus.policy = 'cancel';
              }
              localStorage.clear();
              sessionStorage.clear();
              return;
            }
          });
          return await modal1.present();
        }
      });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.show();
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.apinfoService.applicationStatus();
      this.pagefixService.setTitle();
      this.pagefixService.fixSafeArea();
      this.apinfoService.getApiInfo();
      this.initRouterListen();
      // 唤醒
      this.platform.resume.subscribe(() =>
        this.gestureProvider.callGestureModal()
      );
      // 转到后台
      this.platform.pause.subscribe(() => {
        localStorage.setItem('pauseTime', new Date().toISOString());
      });
    });
  }
}
