import CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToolService {
  // tslint:disable-next-line:variable-name
  public x_PI = (3.14159265358979324 * 3000.0) / 180.0;
  public PI = 3.1415926535897932384626;
  public a = 6378245.0;
  public ee = 0.00669342162296594323;

  constructor() {}

  // 获取随机数
  public GetRandom() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString();
  }

  // SH256加密
  public sha256(str) {
    return CryptoJS.SHA256(str).toString(CryptoJS.enc.Hex);
  }

  // Base64编码
  public base64(str) {
    const wordArray = CryptoJS.enc.Utf8.parse(str);
    return CryptoJS.enc.Base64.stringify(wordArray);
  }

  // 坐标转换
  public wgs84togcj02(coord) {
    if (this.out_of_china(coord[0], coord[1])) {
      return coord;
    } else {
      let dlat = this.transformlat(coord[0] - 105.0, coord[1] - 35.0);
      let dlng = this.transformlng(coord[0] - 105.0, coord[1] - 35.0);
      const radlat = (coord[1] / 180.0) * this.PI;
      let magic = Math.sin(radlat);
      magic = 1 - this.ee * magic * magic;
      const sqrtmagic = Math.sqrt(magic);
      dlat =
        (dlat * 180.0) /
        (((this.a * (1 - this.ee)) / (magic * sqrtmagic)) * this.PI);
      dlng =
        (dlng * 180.0) / ((this.a / sqrtmagic) * Math.cos(radlat) * this.PI);
      const mglat = coord[1] + dlat;
      const mglng = coord[0] + dlng;
      return [mglng, mglat];
    }
  }

  private out_of_china(lng, lat) {
    return (
      lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271 || false
    );
  }

  private transformlat(lng, lat) {
    let ret =
      -100.0 +
      2.0 * lng +
      3.0 * lat +
      0.2 * lat * lat +
      0.1 * lng * lat +
      0.2 * Math.sqrt(Math.abs(lng));
    ret +=
      ((20.0 * Math.sin(6.0 * lng * this.PI) +
        20.0 * Math.sin(2.0 * lng * this.PI)) *
        2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(lat * this.PI) +
        40.0 * Math.sin((lat / 3.0) * this.PI)) *
        2.0) /
      3.0;
    ret +=
      ((160.0 * Math.sin((lat / 12.0) * this.PI) +
        320 * Math.sin((lat * this.PI) / 30.0)) *
        2.0) /
      3.0;
    return ret;
  }

  private transformlng(lng, lat) {
    let ret =
      300.0 +
      lng +
      2.0 * lat +
      0.1 * lng * lng +
      0.1 * lng * lat +
      0.1 * Math.sqrt(Math.abs(lng));
    ret +=
      ((20.0 * Math.sin(6.0 * lng * this.PI) +
        20.0 * Math.sin(2.0 * lng * this.PI)) *
        2.0) /
      3.0;
    ret +=
      ((20.0 * Math.sin(lng * this.PI) +
        40.0 * Math.sin((lng / 3.0) * this.PI)) *
        2.0) /
      3.0;
    ret +=
      ((150.0 * Math.sin((lng / 12.0) * this.PI) +
        300.0 * Math.sin((lng / 30.0) * this.PI)) *
        2.0) /
      3.0;
    return ret;
  }

  // 生GUID
  public guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        // tslint:disable-next-line:no-bitwise triple-equals one-variable-per-declaration
        const r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  // 检验数字
  public checkNumber(num) {
    return /^(0|[1-9][0-9]*)$/.test(num);
  }
  // 检验小数
  public checkFlootNumber(num) {
    return /^([0-9]{1,}[.][0-9]*)$/.test(num);
  }
  // 检验手机号
  public checkPhone(num) {
    return /^[1]([3-9])[0-9]{9}$/.test(num);
  }

  stringToHex(str) {
    let hexString = '';
    for (let i = 0; i < str.length; i++) {
      let hexCharCode = str.charCodeAt(i).toString(16);
      hexString += hexCharCode;
    }
    return hexString;
  }
}
