import {Injectable} from '@angular/core';
import {AlertController, Platform, ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertInfoProvider {
  constructor(private platform: Platform,
              private toastController: ToastController,
              private alertController: AlertController) {
  }

  async presentToast(message, position?, color?) {
    const toast = await this.toastController.create({
      message,
      duration: 2500,
      mode: 'ios',
      position: position ?? 'bottom',
      color: color ?? 'warning'
    });
    await toast.present();
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: '提示',
      message,
      mode: 'ios',
      buttons: [{
        text: '确定',
        cssClass: 'primary',
        handler: () => {
        }
      }]
    });
    await alert.present();
  }

  async presentAlertByTitle(title: string, message: string) {
    const alert = await this.alertController.create({
      header: title,
      message,
      mode: 'ios',
      buttons: [{
        text: '确定',
        cssClass: 'primary',
        handler: () => {
        }
      }]
    });
    await alert.present();
  }

  presentAlertByStatus(title: string, message: string): Promise<boolean> {
    return new Promise(async resolve => {
      const alert = await this.alertController.create({
        header: title,
        message,
        mode: 'ios',
        buttons: [{
          text: '取消',
          role: 'cancel',
          handler: () => {
            alert.dismiss().then(() => resolve(false));
            return false;
          }
        }, {
          text: '确定',
          cssClass: 'primary',
          handler: () => {
            alert.dismiss().then(() => resolve(true));
            return true;
          }
        }]
      });
      await alert.present();
    });
  }

  presentAlertWithCallback(title: string, message: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const confirm = await this.alertController.create({
        header: title,
        message,
        backdropDismiss: false,
        buttons: [{
          text: '取消',
          role: 'cancel',
          handler: () => {
            confirm.dismiss().then(() => resolve(false));
            return false;
          }
        }, {
          text: '确定',
          handler: () => {
            confirm.dismiss().then(() => resolve(true));
            return true;
          }
        }]
      });

      return confirm.present();
    });
  }

  presentAlertWithCallbackCustomButton(title: string, message: string, leftButton: string, rightButton: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const confirm = await this.alertController.create({
        header: title,
        message,
        buttons: [{
          text: leftButton,
          role: 'cancel',
          handler: () => {
            confirm.dismiss().then(() => resolve(false));
            return false;
          }
        }, {
          text: rightButton,
          handler: () => {
            confirm.dismiss().then(() => resolve(true));
            return false;
          }
        }]
      });
      return confirm.present();
    });
  }

  // 提示弹窗（没有取消）
  presentAlertWithMustConfirm = (header, message) => new Promise(resolve => {
    this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      buttons: [{
        text: '确定',
        handler: () => {
          resolve(true);
        }
      }]
    }).then((confirm) => {
      confirm.present().then(r => {
      });
    });
  })
}
