<div style="width: 100%;position: relative">
  <ion-row class="top-title">
    <ion-col size="6" style="text-align: left">
      <span class="font-15" *ngIf="isShowClose"
            tappable (click)="closeGesture('close')">关闭</span>
    </ion-col>
    <ion-col size="6" style="text-align: right">
      <span class="font-15 color-red" *ngIf="isShowForgest"
            tappable (click)="closeGesture()">忘记密码</span>
    </ion-col>
  </ion-row>
  <div style="text-align: center;margin-top: 20%;">
    <img [src]="globalVariable?.imgUrl?.loginBannerUrl" style="width: 50%">
  </div>
  <div class="tip-word">
    {{title}}
  </div>
  <!-- <gesture-password style="height: 350px;width: 100%" #gesture
                    [(ngModel)]="pwd" [type]="type" [options]="options"
                    (error)="onError($event)"
                    (checked)="onChecked($event)"
                    (beforeRepeat)="onBeforeRepeat($event)"
                    (afterRepeat)="onAfterRepeat($event)"></gesture-password> -->
</div>
