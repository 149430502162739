import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoDataComponent} from './no-data.component';

@NgModule({
  declarations: [NoDataComponent],
  imports: [
    CommonModule
  ],
  exports: [NoDataComponent]
})
export class NoDataModule {
}
