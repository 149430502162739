import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { AppUserService } from '../services/app-user.service';
import { GlobalVariable } from '../config/variable';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  globalVariable = GlobalVariable;
  constructor(private appUserService: AppUserService) {}

  // 获取随机数
  getRandom() {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0].toString();
  }

  // SHA256加密
  sha256(message: string) {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
  }

  // base64加密
  base64(message: string) {
    const wordArray = CryptoJS.enc.Utf8.parse(message);
    return CryptoJS.enc.Base64.stringify(wordArray);
  }

  // 获取当前时间戳
  getTimeStamp() {
    return Date.parse(new Date().toString()).toString();
  }

  // 获取时间戳
  getDateStamp() {
    return new Date().getTime();
  }

  // 生GUID
  guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: any) => {
      // tslint:disable-next-line:no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line:no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  // 生成头 authorization
  getHeader() {
    let secret = '';
    let appKey = '';
    const random = this.getRandom();
    const timeStamp = this.getTimeStamp();
    const signature = this.sha256(random + timeStamp + secret);
    return this.base64(`${appKey}.${random}.${timeStamp}.${signature}`);
  }

  // AES加密
  aes(message: string) {
    let secret = '';
    return CryptoJS.AES.encrypt(message, CryptoJS.enc.Utf8.parse(secret), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  }

  // AES256加密
  aes256(message: string, secret: string, iv: string) {
    var ciphertext = CryptoJS.AES.encrypt(
      message,
      CryptoJS.enc.Hex.parse(secret),
      {
        iv: CryptoJS.enc.Hex.parse(iv),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return ciphertext.toString();
  }

  // rsa加密
  rsaEncrypt = (password: string) =>
    new Promise((reslove: any) => {
      let encryptor = new JSEncrypt(); // 创建加密对象实例
      let rsaPassWord: any = password; // 对内容进行加密
      //之前生成的公钥，复制的时候要小心不要有空格(此处把密钥省略了，自己写的时候可把自己生成的公钥粘到对应位置)
      // this.appUserService.GetRSAPubKey().subscribe((r: any) => {
      encryptor.setPublicKey(this.globalVariable.rsaPublickKey); //设置公钥
      if (this.globalVariable.rsaPublickKey)
        rsaPassWord = encryptor.encrypt(password);
      reslove(rsaPassWord);
      // })
    });

  // 使用rsa公钥加密
  rsaPublicEncrypt(message: string) {
    // console.log('明文', message);
    let encryptor = new JSEncrypt(); // 创建加密对象实例
    // console.log('公钥', this.globalVariable.rsaPublickKey);
    encryptor.setPublicKey(this.globalVariable.rsaPublickKey); //设置公钥
    let s: any = encryptor.encrypt(message);
    // console.log('--------加密----', s);
    return s;
  }

  rsaPrivatDecrypt(message: any) {
    console.log('密文', message);
    let encryptor = new JSEncrypt(); // 创建加密对象实例
    encryptor.setPrivateKey(`-----BEGIN RSA PRIVATE KEY-----
    MIIEpAIBAAKCAQEArlj8ubV6UH73SGXthme26FPzueWv79COe6hFE1h2quCopD1L
    qKBxiaVADMUKg8Nr0fiWIYl/3HN21GNCg/ThrIWvBvxkfHb/OYyYgIGLzyLI/wfJ
    VKuvu/cOYviRW+SnG7UAsC9v+xOlAPzfgVPok17Gwz6MTK0eXThEOgYfStqKd0Z6
    9G1iIBNq/GOFzIpGQgwIWSHR997+q3VISdzAX/hlLsbSdocMjm8CgBjBmi3OMBZp
    iqTzarcj+0AOJgxCGqTpwU0QxICtr6dcZ1FFBfsv4772XjfhQxPjbic4nr5TjPPj
    QArMMLqzGJcLgws0Dkm8E6PHt+uMeLlU5saijwIDAQABAoIBAQCX/ZHMn+3SsO0I
    m3s3WcE1xsJKBHedXDZqjOYzGELTcujxzVHg3oY8VeiBeWFSPxIGyWywLftehvZH
    74R0Hw6B5UURlQRRETgsxmnIVac5W3zX/8S/YLDh714GX+Xws2QPHntMHP7FZnnJ
    Y6nBSBIQL2adnTCYQ6+d2sh3wRCUooHuOzaRY3FaCvKIRfSW/c/LDxQED1BPQvA7
    /KYTgJqYEAn/AtLvSMUrF9uI5OQBtbaPD9hdiHl8LCjdXbY4TSmMIpW5gRDZxP9x
    3RiQ5IJlJJ7smJBe9OjtJC//u6D48oAVzQZ3n8/we6cI28D3U1Ph92ozHHyFiKfX
    S4KRrsBpAoGBANiQqUYomZNvapPSK1hZqP/2FwxAg9XPBu8REThbSv9qsNVSpldF
    Oa0t2UOi4BfkEX0y5mpBAMHOmUV78eZr9sXeUXWg0xicjDmzKT9RXhC+p2LMSlUz
    lkVNYRcNf5Qcf46jl4sPQGtwnx751knVP7iucvpzFhYQpVL2PWxcWEIDAoGBAM4Y
    U97NkNx/ZYX0U7ZmOpz7eVHO6VKz/kebzYahossvSzM4PAUyB4mmRjfOSPbk/Dgp
    fLFUWL8XgVwr8UHWF249qSGQUUX84uoT44izVRS3taSbW797Kbe7OnLaIVnGnqKa
    /K3FO5mdVZNNZhR3HVqK3KgjXv4U9VctV+ELJh2FAoGBAMAQ1D+nxESQ3LnK/Kw3
    oH2ZuGkiUonuOR0PSvFrbgFGkxwuV94wQ+3GKtkke5BUsfoSiW2UxuIbo62PNkOA
    XvSvrhkfj/hU5rxJm/xO4pkBLZyeyBH2VYCTFteGHi6yc6KCjeVDmoNu94BWXWlP
    2lqlRM3AAceB7c29G06eO5wXAoGAB/JaMYodntLuuDaOj8brVahAqk3fI0g7cslo
    KFvNKiZ9sNDnR5ABSGZ36Kj0mUiFDSlUXIYTEfM3ErXDDLXTm3WXMuscpyU0HrB7
    ooa/rhfTUjCHXh/CoQoAG/ObO5nz/fMZ8R1cyfSOGyrt17YsFJSsI3ORsxxdxWnD
    LLNAWf0CgYB+fFCWw2hTDdorf3ZFtKXDk/A1XDEc2dMRv9GY/xA/YMfsddjF3NMg
    w+sz+7UD3pj7EqSBPQ6tGfnT3T1IZmGKj6MkzXWkt//OpUa7ZMQBe+NhPxCOUwhd
    iQaUhg4AGCmz9FdWeBiPOFsqsirV75mgrPKJbWQJum/8jSyAwy95aw==
    -----END RSA PRIVATE KEY-----
    `); //设置私钥
    let s: any = encryptor.decrypt(message);
    console.log('--------解密----', s);
    return s;
  }
}
