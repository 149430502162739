import {Injectable} from '@angular/core';
import {LoadingController, Platform} from '@ionic/angular';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {AlertInfoProvider} from './alertInfo.provider';
import {UniqueDeviceID} from '@ionic-native/unique-device-id/ngx';
import {FileTransfer, FileTransferObject} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {VersionUpdateService} from '../services/version-update.service';
import {Device} from '@ionic-native/device/ngx';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AppnativeProvider {
  loading: any;

  constructor(
    private alertInfoProvider: AlertInfoProvider,
    private platform: Platform,
    private iab: InAppBrowser,
    private appVersion: AppVersion,
    private uniqueDeviceID: UniqueDeviceID,
    private loadingCtrl: LoadingController,
    private file: File,
    private fileTransfer: FileTransfer,
    private fileOpener: FileOpener,
    private versionUpdateService: VersionUpdateService,
    private device: Device) {
  }

  // 打开外部链接
  viewDetail(url) {
    let encodeUri = url;
    if (this.platform.is('ios')) {
      encodeUri = encodeURI(url);
    }
    this.iab.create(encodeUri, '_system');
  }

  // 获取APP版本号
  getAppVersion = () => new Promise(resolve => {
    this.appVersion.getVersionNumber()
      .then((version: string) => {
        resolve(version);
      }).catch(err => {
      this.alertInfoProvider.presentAlert(err);
    });
  })

  // 获取设备的唯一ID
  uniqueDeviceId = () => new Promise((resolve: any) => {
    this.uniqueDeviceID.get()
      .then((uuid: string) => {
        resolve(uuid);
      })
      .catch(err => {
        this.alertInfoProvider.presentAlert(err);
      });
  })

  // 获取本地设备信息
  async getDeviceInfo(): Promise<any> {
    return {
      DeviceType: this.platform.is('ios') ? 'ios' : 'android',
      AppVersion: await this.getAppVersion()
    };
  }

  // 获取服务器版本号
  getRemoteInfo = (model: any) => new Promise((resolve: any, reject) => {
    this.versionUpdateService.GetVersionModify(model).subscribe(res => {
      resolve({
        downloadUrl: res.downloadUrl,
        appVersion: res.appVersion,
        updateStatus: res.updateStatus
      });
    });
  })

  // 更新并下载
  async updateVersion(downloadUrl: string) {
    // 安卓下可以直接更新
    if (this.platform.is('android')) {
      this.loading = await this.loadingCtrl.create({
        message: '正在下载...',
        spinner: 'circles',
        backdropDismiss: false,
        mode: 'ios'
      });
      await this.loading.present();
      const filename = downloadUrl.split('/').pop();
      const targetPath = this.file.externalDataDirectory + filename;
      const trustHosts = true;
      const options = {};
      const fileTransfer: FileTransferObject = this.fileTransfer.create();
      // 下载进度
      fileTransfer.onProgress((progressEvent) => {
        this.loading.message = `正在下载... ${((progressEvent.loaded / progressEvent.total) * 100).toFixed(0)}%`;
        if (progressEvent.loaded === progressEvent.total) {
          this.loading.dismiss();
        }
      });
      // 开始下载
      await fileTransfer.download(downloadUrl, targetPath, trustHosts, options).then((result) => {
        this.fileOpener.open(result.toURL(), 'application/vnd.android.package-archive');
      }).catch((error => {
        this.loading.dismiss();
        this.alertInfoProvider.presentAlertWithMustConfirm('提示', '网络质量不佳下载失败,跳转到本机下载器下载并安装').then(() => {
          this.viewDetail(downloadUrl);
        });
      }));
    } else {
      // ios下跳转至下载页面
      this.iab.create(downloadUrl, '_system');
    }
  }


  async alertUpdateInfo() {
    if (environment.hmr) {
      return;
    }
    const appVersionNumber = await this.getAppVersion();
    const deviceInfo = await this.getDeviceInfo();
    // @ts-ignore
    const {downloadUrl, appVersion, updateStatus} = await this.getRemoteInfo(deviceInfo);
    // tslint:disable-next-line:triple-equals
    if (appVersionNumber != appVersion) {
      if (updateStatus === 'FORCE') {
        this.alertInfoProvider.presentAlertWithMustConfirm('提示', `发现最新版本${appVersion}请立即更新`).then(s => {
          this.updateVersion(downloadUrl);
        });
      } else {
        this.alertInfoProvider.presentAlertWithCallback('提示', `发现最新版本${appVersion}是否立即更新`).then(s => {
          if (s) {
            this.updateVersion(downloadUrl);
          }
        });
      }
    }
  }

}
