<ion-content>
  <!-- 内容 -->
  <div class="privay-content" style="padding: 10px 10px 0px;">
    <h1 style="text-align: center;font-size: 22px;">用户知情同意书</h1>
    <div [innerHTML]="paraContent"></div>
    <div class="check-box">
      <div *ngFor="let item of buttons" class="check-item">
        <input type="checkbox" 
          [name]="item.sort" 
          [(ngModel)]="item.isConsent" 
          (ngModelChange)="updateAllChecked($event)"/>
        <span>
          <!-- buttonText -->
          {{item.buttonText}}

        </span>
      </div>
    </div>
  </div>
  

  <!-- 测试div -->
  <div id="test" style="height:10px"></div>

</ion-content>

<!-- 页脚 -->
<ion-footer>
  <ion-row>
    <ion-col>
      <ion-button shape="round" expand="block"  (click)="agreePrivacy(false)">不同意</ion-button>
    </ion-col>
    <ion-col>
      <ion-button shape="round" expand="block"  [disabled]="!(isTouchBottom && checkAllFlag)"  (click)="agreePrivacy(true)">同意</ion-button>
    </ion-col>
  </ion-row>
</ion-footer>