import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {SwitchTerritoryModalComponent} from './switch-territory-modal.component';
import {NoDataModule} from '../../../components/no-data/no-data.module';

@NgModule({
  declarations: [SwitchTerritoryModalComponent],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    NoDataModule
  ],
  exports: [SwitchTerritoryModalComponent]
})
export class SwitchTerritoryModalModule {
}
