import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalVariable } from 'src/app/config/variable';
import { CryptoService } from 'src/app/providers/crypto.service';
import { ToolService } from 'src/app/providers/tool.service';
import { AppUserService } from 'src/app/services/app-user.service';

@Component({
  selector: 'xsk-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  loginBannerUrl: string;
  userName: string;
  password: any;

  constructor(
    private appUserService: AppUserService,
    private cryptoService: CryptoService,
    private modalController: ModalController,
    private toolService: ToolService
  ) {
    this.loginBannerUrl = GlobalVariable.imgUrl.loginBannerUrl;
  }

  doLogin() {
    let p: any = this.cryptoService.rsaPublicEncrypt(this.password);
    // rsa加密
    this.appUserService
      .LoginForLdapApp({
        loginName: this.userName,
        password: this.toolService.stringToHex(p),
      })
      .subscribe((res) => {
        console.log(res);
        localStorage.setItem('app_token', localStorage.getItem('token'));
        localStorage.setItem(
          'app_token_version',
          localStorage.getItem('token_version')
        );
        this.modalController.dismiss({}, 'success');
      });
    // 登录密码换成aes256校验
    // this.appUserService.getSecretAndIv().subscribe(res => {
    //   if (res) {
    //     let secret = res;
    //     this.appUserService.LoginForLdapApp({
    //       loginName: this.userName,
    //       password: this.cryptoService.aes256(this.password, secret, 'F642A8D99E92C6B854417FC8401214EC')
    //     }).subscribe(res => {
    //       console.log(res);
    //       localStorage.setItem('app_token', localStorage.getItem('token'));
    //       localStorage.setItem('app_token_version', localStorage.getItem('token_version'));
    //       this.modalController.dismiss({}, 'success');
    //     });
    //   }
    // })

    // // 登录密码使用base64编码
    // this.appUserService.LoginForLdapApp({
    //   loginName: this.userName,
    //   password: this.cryptoService.base64(this.password)
    // }).subscribe(res => {
    //   console.log(res);
    //   localStorage.setItem('app_token', localStorage.getItem('token'));
    //   localStorage.setItem('app_token_version', localStorage.getItem('token_version'));
    //   this.modalController.dismiss({}, 'success');
    // });
  }

  getVerifCode() {
    console.log('获取验证码');
  }

  GetStaffQrEmail() {
    this.appUserService.GetStaffQrEmail().subscribe((res) => {
      this.userName = res;
    });
  }

  ngOnInit() {
    this.GetStaffQrEmail();
  }
}
