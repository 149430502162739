import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { APP_PROVIDERS } from './app.provider';
import { AuthenticationAbortedComponent } from './components/authentication-aborted/authentication-aborted.component';
import { AuthenticationLandingComponent } from './components/authentication-landing/authentication-landing.component';
import { SharedModule } from './shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Interceptor } from 'src/interceptors/app.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationAbortedComponent,
    AuthenticationLandingComponent,
  ],
  imports: [SharedModule],
  providers: [
    APP_PROVIDERS,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
