import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { XskBasisModule } from 'ng-upgrade-basis';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/setting/about/about.module').then(m => m.AboutPageModule),
    data: { title: '关于' }
  },
  {
    path: 'coaching',
    loadChildren: () => import('./pages/coaching/coaching.module').then(m => m.CoachingPageModule),
    data: { title: '协访' }
  },
  {
    path: 'institution',
    loadChildren: () => import('./pages/institution/institution.module').then(m => m.InstitutionPageModule),
    data: {title: '客户信息'}
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then(m => m.TestPageModule),
    data: { title: '测试' }
  },
  {
    path: 'targetClient',
    loadChildren: () => import('./pages/target-client/target-client.module').then(m => m.TargetClientPageModule),
    data: {title: '目标客户'}
  },
  {
    path: 'message',
    loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule),
    data: { title: '消息' }
  },
  {
    path: 'develop',
    loadChildren: () => import('./pages/develop/develop.module').then(m => m.DevelopPageModule),
    data: { title: '开发医院' }
  },
  {
    path: 'test-token',
    loadChildren: () => import('./pages/test-token/test-token.module').then(m => m.TestTokenPageModule),
    data: { title: '鉴权信息' }
  },
  {
    path: 'library-history',
    loadChildren: () => import('./pages/library/library-history/library-history.module').then(m => m.LibraryHistoryPageModule),
    data: { title: '播放历史' }
  },
  {
    path: 'library-folder/:collectionId',
    loadChildren: () => import('./pages/library/library-folder/library-folder.module').then(m => m.LibraryFolderPageModule),
    data: { title: '资料库' }
  },
  {
    path: 'view-library',
    loadChildren: () => import('./pages/library/view-library/view-library.module').then(m => m.ViewLibraryModule),
    data: { title: '资料查看' }
  },
  {
    path: 'approval',
    loadChildren: () => import('./pages/approval/approval.module').then(m => m.ApprovalPageModule),
    data: { title: '审批' }
  },
  {
    path: 'saleWeekFill',
    loadChildren: () => import('./pages/sale-week-fill/sale-week-fill.module').then(m => m.SaleWeekFillPageModule),
    data: { title: '销量周填报' }
  },
  {
    path: 'create-plan',
    loadChildren: () => import('./pages/call/create-plan/create-plan.module').then( m => m.CreatePlanPageModule)
  },
  {
    path: 'call-institution',
    loadChildren: () => import('./pages/calendar/call-institution/call-institution.module').then(m => m.CallInstitutionPageModule)
  },
  {
    path: 'call-overview',
    loadChildren: () => import('./pages/call/call-overview/call-overview.module').then(m => m.CallOverviewPageModule),
    data: { title: '概览' }
  },
  {
    path: 'checkin',
    loadChildren: () => import('./pages/call/checkin/checkin.module').then(m => m.CheckinPageModule),
    data: { title: '签到' }
  },
  {
    path: 'visiting-plan',
    loadChildren: () => import('./pages/call/visiting-plan/visiting-plan.module').then(m => m.VisitingPlanPageModule)
  },
  {
    path: 'routing',
    loadChildren: () => import('./pages/routing/routing.module').then(m => m.RoutingPageModule),
    data: { title: '路线设定' }
  },
  {
    path: 'schedule',
    loadChildren: () => import('./pages/schedule/schedule.module').then(m => m.SchedulePageModule),
    data: { title: '日程管理' }
  },
  {
    path: 'campaign',
    loadChildren: () => import('./pages/campaign/campaign.module').then(m => m.CampaignPageModule)
  }, {
    path: 'callguide',
    loadChildren: () => import('./pages/callguide/callguide.module').then(m => m.CallguidePageModule),
    data: {title: '拜访向导'}
  },
  {
    path: 'funnel-list',
    loadChildren: () => import('./pages/Funnel/funnel-list/funnel-list.module').then( m => m.FunnelListPageModule),
    data: {title: 'Funnel'}
  },
  {
    path: 'funnel-detail/:activityID',
    loadChildren: () => import('./pages/Funnel/funnel-detail/funnel-detail.module').then( m => m.FunnelDetailPageModule),
    data: {title: 'Funnel-填写'}
  },
  {
    path: 'funnel-detail-edit/:activityID',
    loadChildren: () => import('./pages/Funnel/funnel-detail/funnel-detail.module').then( m => m.FunnelDetailPageModule),
    data: {title: 'Funnel-修改'}
  },
  {
    path: 'sales-target',
    loadChildren: () => import('./pages/sales-target/sales-target.module').then( m => m.SalesTargetPageModule)
  },
  {
    path: 'sales-weekly-target',
    loadChildren: () => import('./pages/sales-weekly-target/sales-weekly-target.module').then( m => m.SalesWeeklyTargetPageModule)
  },
  {
    path: 'micro-market',
    loadChildren: () => import('./pages/micro-market/micro-market.module').then( m => m.MicroMarketPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },


];

@NgModule({
  imports: [
    XskBasisModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
