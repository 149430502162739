export const postProcessEnv = (env) => {
    if (env.hmr == null) {
        env.hmr = env.build.deployment == null;
    }
    if (env.production == null) {
        env.production = ['prd', 'stg'].indexOf(env.build.deployment?.toLowerCase()) >= 0;
    }
    if (env.isApp == null) {
        env.isApp = ['app', 'ios', 'android'].indexOf(env.build.viewport?.toLowerCase()) >= 0;
    }
};
