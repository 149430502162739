import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppUserService} from '../../../services/app-user.service';
import {GeneralService} from 'ng-upgrade-common';
import {AlertInfoProvider} from '../../../providers/alertInfo.provider';
import {GlobalVariable} from '../../../config/variable';

const LOCAL_STORAGE_KEY_ACCOUNT = 'acount';

@Component({
  selector: 'app-switch-territory-modal',
  template: `
    <ion-content>
      <ion-toolbar color="sky" class="sticky-bar">
        <ion-buttons slot="start" *ngIf="isShowCloseButton">
          <ion-button (click)="close()">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
          <ng-content select=".button-start"></ng-content>
        </ion-buttons>
        <ion-title class="title">请选择岗位</ion-title>
      </ion-toolbar>
      <div style="padding:10px;max-height:500px;overflow-y:scroll">

        <ion-radio-group [value]="checkedValue">
          <ion-row *ngFor="let territory of territoryList" (click)="selectedTerritory(territory)"
                   style="border-bottom:1px solid rgba(204,204,204,.4);margin-top:2px;">
            <ion-col size="2" style="text-align: center;">
              <ion-icon style="color: #32A3E7;font-size:21px;" name="person-circle"></ion-icon>
            </ion-col>
            <ion-col>
              <span style="font-size:15px;">{{territory.territoryName}}</span>
            </ion-col>
            <ion-radio [value]="territory.territoryId" class="margin-top2 margin-right10">
            </ion-radio>
          </ion-row>
        </ion-radio-group>
        <xsk-no-data *ngIf="territoryList?.length==0"></xsk-no-data>
      </div>
    </ion-content>`,
  styles: [`
    .div-wrap {
    }
  `],
})
export class SwitchTerritoryModalComponent implements OnInit {
  territoryList: any = [];
  @Input() checkedValue = '';
  @Input() isShowCloseButton: boolean;

  constructor(private modalCtrl: ModalController,
              private appUserService: AppUserService,
              private generalService: GeneralService,
              private alertInfoProvider: AlertInfoProvider) {
  }

  close() {
    this.modalCtrl.dismiss({
      action: 'cancel'
    });
  }

  selectedTerritory(territory: any) {
    this.appUserService.SwitchTerritery(territory.territoryId).subscribe(res => {
      const {permissions} = this.generalService.getAccountInfo();
      if (permissions.length === 0) {
        this.alertInfoProvider.presentAlertWithCallback('提示', '当前岗位没有权限访问系统!').then((status) => {
          if (status) {
            this.modalCtrl.dismiss({
              action: 'save',
              territory
            });
          }
        });
      } else {
        this.modalCtrl.dismiss({
          action: 'save',
          territory
        }).then(() => {
        });
      }
    });
  }

  ngOnInit() {
    this.territoryList = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ACCOUNT) ?? '{}').territoryItems ?? [];
  }

}
