import {Injectable} from '@angular/core';
import {
  EVENT_TOPIC_API_COMPLETED,
  EVENT_TOPIC_API_ERROR,
  EVENT_TOPIC_API_REQUESTING,
  STATUS_NAME_API_REQUESTING
} from 'ng-upgrade-api';
import {GeneralService} from 'ng-upgrade-common';
import {Router} from '@angular/router';
import {AlertController, LoadingController, Platform} from '@ionic/angular';
import {environment} from '../../environments/environment';
import {GlobalVariable} from '../config/variable';
import {DevelopServeToken} from '../config/token';
import {AppUserService} from '../services/app-user.service';
import VConsole from 'vconsole';
import {PagefixService} from './pagefix.service';
import {PopupNoticeService} from './popup-notice.service';

const LOCAL_STORAGE_KEY_TOKEN = 'token';
const LOCAL_STORAGE_KEY_TOKEN_VERSION = 'token_version';
const LOCAL_STORAGE_KEY_ACCOUNT = 'acount';
const LOCAL_STORAGE_KEY_SELECTTERRITORY = '_selectedTerritory';
const GESTURE_PASSWORD = '_gesture';

@Injectable({
  providedIn: 'root'
})
export class ApinfoService {
  globalVariable = GlobalVariable;
  loading: any;
  alert: any;

  constructor(private generalService: GeneralService,
              private router: Router,
              private loadingController: LoadingController,
              private alertController: AlertController,
              private appUserService: AppUserService,
              private pagefixService: PagefixService,
              private platform: Platform,
              private popupNoticeService: PopupNoticeService) {
  }

  public async getApiInfo() {
    this.generalService.subscribeEvent([EVENT_TOPIC_API_ERROR], (err) => {
      console.log('错误', err);
      this.collectFailedHttp(err.data ? err.data : err);
    });
    this.generalService.eventWithStatus(
      [EVENT_TOPIC_API_REQUESTING, EVENT_TOPIC_API_COMPLETED], STATUS_NAME_API_REQUESTING,
      (res) => {
        if (res) {
          this.globalVariable.loadingIsShow = res.length > 0;
        }
      });
  }

  private async alertShow(err: any) {
    let message = '';
    if (err.isLogic) {
      message = err.isLogic ? (err.message ? err.message : '您没有权限访问系统') : '您没有权限访问系统';
    } else if (err.code) {
      message = err.code ? err.code : '服务器发生错误，请检查服务器';
    } else if (err.httpStatus) {
      switch (err.httpStatus) {
        case 500:
          message = '服务器发生错误，请检查服务器';
          break;
        case 502:
          message = '网关错误';
          break;
        case 503:
          message = '服务不可用，服务器暂时过载或维护';
          break;
        case 504:
          message = '网关超时';
          break;
        default:
          message = '服务器发生错误，请检查服务器';
          break;
      }
    } else {
      message = '服务器发生错误，请检查服务器';
    }
    this.globalVariable.alertingIsShow = true;
    this.globalVariable.alertingMessage = message;
  }

  // 401跳到登录页面
  private async permissToLogin(httpStatus: any, message: string) {
    await this.noticeMessage(message);
    // tslint:disable-next-line:triple-equals
    if (environment.isApp) {
      await this.router.navigate(['login']);
    } else {
      this.globalVariable.authenticationStatus.status = 'aborted';
      // tslint:disable-next-line:triple-equals
      this.globalVariable.authenticationStatus.message = httpStatus == 401 ? '身份信息已过期,请重新登录' : '当前岗没有访问权限！';
    }
    sessionStorage.clear();
    localStorage.clear();
  }

  private noticeMessage = (message) => new Promise(res => {
    this.globalVariable.alertingIsShow = true;
    this.globalVariable.alertingMessage = message;
    res(null);
  })

  public collectFailedHttp(err: any) {
    this.pagefixService.closeAllPageLayer();
    // 处理错误请求
    switch (err.httpStatus) {
      case 401:
        this.permissToLogin(401, '身份信息已过期,请重新登录');
        break;
      case 403:
        this.alertShow(err);
        break;
      default:
        this.alertShow(err);
        this.globalVariable.authenticationStatus.message = err.message;
    }
    this.pagefixService.closeLoading();
  }

  getKillPressAccountInfo() {
    const accountInfo: any = JSON.parse(localStorage.getItem('acount') ?? '{}');
    this.globalVariable.currentAccountInfo.selectedTerritory = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_SELECTTERRITORY) ?? '{}');
    this.globalVariable.currentAccountInfo.permissions = accountInfo.permissions;
    this.globalVariable.currentAccountInfo.staffName = accountInfo.displayName;
    this.globalVariable.currentAccountInfo.staffId = accountInfo.staffId;
    this.globalVariable.currentAccountInfo.userId = accountInfo.loginName;
    this.globalVariable.currentAccountInfo.territoryItems = accountInfo.territoryItems;
    this.globalVariable.selectedUnderlingStaff.staffId = accountInfo.staffId;
    // this.globalVariable.selectedUnderlingStaff.territoryId = this.globalVariable.currentAccountInfo.selectedTerritory.territoryId;
    if (this.platform.is('ios')) {
      this.globalVariable.deviceInfo.name = 'ios';
    } else if (this.platform.is('android')) {
      this.globalVariable.deviceInfo.name = 'android';
    } else {
      this.globalVariable.deviceInfo.name = 'other';
    }
  }

  async getAccountInfo() {
    const accountInfo: any = JSON.parse(localStorage.getItem('acount') ?? '{}');
    this.globalVariable.currentAccountInfo.selectedTerritory = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_SELECTTERRITORY) ?? '{}');
    this.globalVariable.currentAccountInfo.permissions = accountInfo.permissions;
    this.globalVariable.currentAccountInfo.staffName = accountInfo.displayName;
    this.globalVariable.currentAccountInfo.staffId = accountInfo.staffId;
    this.globalVariable.currentAccountInfo.userId = accountInfo.loginName;
    this.globalVariable.currentAccountInfo.territoryItems = accountInfo.territoryItems;
    this.globalVariable.selectedUnderlingStaff.staffId = accountInfo.staffId;
    // this.globalVariable.selectedUnderlingStaff.territoryId = this.globalVariable.currentAccountInfo.selectedTerritory.territoryId;
    if (this.platform.is('ios')) {
      this.globalVariable.deviceInfo.name = 'ios';
    } else if (this.platform.is('android')) {
      this.globalVariable.deviceInfo.name = 'android';
    } else {
      this.globalVariable.deviceInfo.name = 'other';
    }
    await this.popupNoticeService.getNotice();
    await this.getVconsoleIsShow();
  }

  getVconsoleIsShow(): Promise<any> {
    return new Promise((resolve1, reject) => {
      if (!this.globalVariable.isDebugConsole) {
        resolve1(null);
        return;
      }
      const vconsoleShow: any = document.querySelector('#__vconsole');
      if (vconsoleShow) {
        resolve1(null);
        return;
      }
      this.appUserService.Vconsole().subscribe(res => {
        if (res) {
          const vConsole = new VConsole();
        }
        resolve1(null);
      });
    });
  }

  public applicationStatus() {
    setTimeout(() => {
      if (environment.isApp) {
        // tslint:disable-next-line:max-line-length
        if (!localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) || !localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_VERSION) || !localStorage.getItem(GESTURE_PASSWORD)) {
          this.pagefixService.closeAllPageLayer();
          this.router.navigate(['login']);
        }
      }
    }, 1);
    // 仅适用于微信开发调试
    // if (!environment.isApp && DevelopServeToken.token) {
    //   localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, DevelopServeToken.token);
    //   localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN_VERSION, DevelopServeToken.token_version);
    //   this.globalVariable.authenticationStatus.status = 'success';
    //   this.generalService.publishEvent('landingSuccess', {});
    // }
    // 功能完善时放开
    // if (!localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) || !localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN_VERSION)) {
    //   if (environment.isApp) {
    //     setTimeout(() => {
    //       this.router.navigate(['login']);
    //     }, 1);
    //   } else {
    //     this.globalVariable.authenticationStatus.status = 'landing';
    //   }
    // }
  }


}
