import {Component, OnInit} from '@angular/core';
import {GlobalVariable} from '../../config/variable';

@Component({
    selector: 'app-authentication-aborted',
    template: `
        <div class="auth-info">
            <div>
                <img src="assets/icon/info_warning.png">
                <p>{{globalVariable.authenticationStatus.message}}</p>
            </div>
        </div>
    `,
    styleUrls: ['./authentication-aborted.component.scss']
})
export class AuthenticationAbortedComponent implements OnInit {
    globalVariable = GlobalVariable;

    constructor() {
    }

    ngOnInit() {
    }

}
