import { Inject, Injectable } from '@angular/core';
import { ApiService, API_SERVICE } from 'ng-upgrade-api';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppUserService {
  constructor(@Inject(API_SERVICE) private apiService: ApiService) {}

  LoginForWechat(model: any): Observable<any> {
    return this.apiService.postJson(`RepUser/LoginByEnterpriseWechat`, {
      body: model,
    });
  }

  LoginForApp(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`RepUser/LoginByPassword`, {
      body: model,
    });
  }

  Logout(): Observable<any> {
    if (environment.isApp) {
      // @ts-ignore
      return this.apiService.getJson(`RepUser/LogoutByPassword`);
    }
    return this.apiService.getJson(`RepUser/LogoutByEnterpriseWechat`);
  }

  /*
   * 选择岗位
   * */
  SwitchTerritery(territoryId: string): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`RepUser/SwitchTerritery`, {
      body: { territoryId },
    });
  }

  /*
   * Vconsole
   * */
  Vconsole(): Observable<any> {
    return this.apiService.getJson(`System/GetVconsoleInfo`);
  }

  ChangeStaffPassword(model: any): Observable<any> {
    return this.apiService.postJson(`RepStaff/ChangeStaffPassword`, {
      body: model,
    });
  }

  // 获取rsa公钥
  getRSAPubKey(): Observable<any> {
    return this.apiService.getJson(`System/GetRSAPubKey`);
  }

  GetIsEnableLogin(): Observable<any> {
    return this.apiService.getJson(`Wow/GetIsEnableLogin`);
  }

  LoginForLdapApp(model: any): Observable<any> {
    // @ts-ignore
    return this.apiService.postJson(`RepUser/LoginByLDAP`, {
      body: model,
    });
  }

  GetStaffQrEmail(): Observable<any> {
    return this.apiService.getJson(`Wow/GetStaffQrEmail`);
  }

  //获取知情同意书
  GetActiveInformedConsent(model: any): Observable<any> {
    return this.apiService.postJson(
      `InformedConsent/GetActiveInformedConsent`,
      {
        body: model,
      }
    );
  }

  // 保存知情同意书
  SaveUserAgreeRecordPost(model: any): Observable<any> {
    return this.apiService.postJson(`InformedConsent/SaveUserAgreeRecord`, {
      body: model,
    });
  }
}
