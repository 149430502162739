<ion-app>
  <ion-router-outlet *ngIf="environment?.isApp"></ion-router-outlet>
  <ng-container *ngIf="!environment?.isApp">
    <!--鉴权失败-->
    <app-authentication-aborted
      *ngIf="globalVariable?.authenticationStatus?.status == 'aborted'"></app-authentication-aborted>
    <!--鉴权中-->
    <app-authentication-landing
      *ngIf="globalVariable?.authenticationStatus?.status == 'landing'"></app-authentication-landing>
    <!--鉴权成功显示内容-->
    <ion-router-outlet
      *ngIf="globalVariable?.authenticationStatus?.status == 'success'"></ion-router-outlet>
  </ng-container>
  <div class="wrap-loading" [hidden]="!globalVariable?.loadingIsShow">
    <div class="box-loading">
      <ion-spinner name="lines" class="loading-spinner" color="light"></ion-spinner>
    </div>
  </div>
  <div class="wrap-loading bg-black05" *ngIf="globalVariable?.alertingIsShow">
    <div class="box-altering">
      <h3 class="font-16 font-weight text-center">提示</h3>
      <div class="font-15 color-dark text-center text-normal altering-content">
        {{globalVariable?.alertingMessage}}
      </div>
      <ion-button color="primary" fill="clear" expand="full" class="font-14"
                  (click)="closeAlertModal()">确定
      </ion-button>
    </div>
  </div>
  <!--  一键回到首页 -->
<!--  <div class="back-home" *ngIf="!isShowGoHome && !isTest" tappable (click)="goRootHome()">-->
<!--    <ion-icon name="home"></ion-icon>-->
<!--  </div>-->
</ion-app>
