import {Injectable} from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import {GesturePassComponent} from '../modals/gesture-pass/gesture-pass.component';
import * as moment from 'moment';
import {GlobalVariable} from '../config/variable';
import {AppnativeProvider} from './appnative.provider';

@Injectable({providedIn: 'root'})
export class GestureProvider {
  globalVariable = GlobalVariable;
  gestureModal: any;

  constructor(private platform: Platform,
              private modalController: ModalController,
              private appnativeProvider: AppnativeProvider) {
  }

  // 显示手势密码 recorder check
  showGestureModal(type, isShowForgest, isShowClose, isUpdateGesturePass?: boolean): Promise<any> {
    return new Promise(resolve => {
      if (!this.gestureModal) {
        this.gestureModal = this.modalController.create({
          component: GesturePassComponent,
          componentProps: {
            type,
            isShowForgest,
            isShowClose,
            isUpdateGesturePass
          },
          mode: 'ios'
        }).then((modal: any) => {
          this.gestureModal = modal;
          this.gestureModal.present();
          modal.onWillDismiss().then((data, role) => {
            this.gestureModal = null;
            this.appnativeProvider.alertUpdateInfo();
            resolve(data.role);
          });
        });
      }
    });
  }

  // 呼出手势密码
  callGestureModal() {
    const pauseTime = moment(localStorage.getItem('pauseTime'));
    if (moment().diff(pauseTime) > this.globalVariable.gestureResumeTime && localStorage.getItem('_gesture') != null) {
      this.showGestureModal('check', true, false);
    }
  }


}
